import React from "react";
import ContainerWithShadow from "../CommonComponents/ContainerWithShadow";
import { Button, Tooltip, Typography } from "antd";
import { image69 } from "../../assets/images";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ViewAllOffersModal from "./ViewAllOffersModal";
import { useSelector } from "react-redux";
import IscontinueDifferentLabModal from "../CommonComponents/IsContinueDifferentLabModal";
import { axiosInstance } from "../../utils/axios";
import { toast } from "react-toastify";

const BillDetail = ({
  isButtonVisible,
  billData,
  continueDisable,
  getData,
  CheckOut,
}) => {
  const [isDifferentLabModalOpen, setIsDifferentLabModalOpen] = useState(false);
  const [isOfferOpen, setIsOfferOpen] = useState(false);
  const navigate = useNavigate();

  const checkSlots = async () => {
    try {
      const response = await axiosInstance.get("/api/v1/cart/checkout");
      if (response?.status === 200) {
        navigate("/checkout");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.info(error?.response?.data?.message);
      }
    }
  };

  const isOffer = useSelector((state) => state.alerts.isOfferApplied);

  const isContinue = useSelector((state) => state.offerName.isDifferentLab);
  const handleCheckout = () => {
    if (isContinue) {
      setIsDifferentLabModalOpen(true);
    } else {
      checkSlots();
    }
  };
  const openViewALlOffersInMobile = () => {
    navigate("/cart/view-all-offers");
  };

  const manageOfferModal = () => {
    setIsOfferOpen(!isOfferOpen);
  };

  const addCost =
    billData?.homeSamplingCharge > 0 ? 2000 - billData?.totalAmount : -1;

  return (
    <div className=" md:mt-0 mt-[1rem] mb-5 ">
      <ContainerWithShadow testName="Bill Details">
        <div className="flex justify-between bg-[#FBF0F2]  py-3 sm:px-5 mt-[1rem] mb-[1rem]">
          <div className="flex items-center ">
            <div className="h-[100%] pt-1 ">
              <img
                src={image69}
                alt="Shop"
                className="w-[1.2rem] ml-[0.5rem] sm:ml-0"
              />
            </div>
            <Typography className="ml-[0.5rem] text-[0.85rem] w-[70%] sm:w-full  font-semibold font-sans">
              Want to add more items?
            </Typography>
          </div>
          <Typography
            className="text-[#F15E7E] text-[0.85rem] font-semibold font-sans cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            Shop more
          </Typography>
        </div>

        <div className="border-b-2 mt-2 sm:mt-0">
          <div className="flex justify-between items-center py-[0.6rem] ">
            <Typography className="text-[0.9rem] font-sans">
              Cart Value
            </Typography>
            <Typography className="text-[0.9rem] font-sans">
              ₹{billData?.cartValue}
            </Typography>
          </div>

          {CheckOut ? (
            <>
              {isOffer && (
                <div className="flex justify-between items-center py-[0.6rem]">
                  <Typography className="text-[0.9rem] font-sans">
                    Coupon Discount
                  </Typography>

                  <Typography className="text-[0.9rem] semibold text-[#389E0D] font-sans">
                    -₹{billData?.discount.toFixed(2)}
                  </Typography>
                </div>
              )}
            </>
          ) : (
            <div className="flex justify-between items-center py-[0.6rem]">
              <Typography className="text-[0.9rem] font-sans">
                Coupon Discount
              </Typography>

              {isOffer ? (
                <Typography className="text-[0.9rem] semibold text-[#389E0D] font-sans">
                  -₹{billData?.discount.toFixed(2)}
                </Typography>
              ) : (
                <Typography
                  className="text-[1rem] font-semibold text-[#F15E7E] cursor-pointer underline font-sans"
                  onClick={
                    window.innerWidth > 640
                      ? manageOfferModal
                      : openViewALlOffersInMobile
                  }
                >
                  Apply Coupon
                </Typography>
              )}
            </div>
          )}
          <div className="flex justify-between items-center py-[0.6rem]">
            <Tooltip
              title={
                "This is paid to the collection agent to take extra precautions of your samples"
              }
              color="#f386a8"
            >
              <Typography className="text-[0.9rem] font-sans ">
                Home Collection Charges
              </Typography>
            </Tooltip>
            <Typography className="text-[0.9rem] font-sans">
              ₹{billData?.homeSamplingCharge}
            </Typography>
          </div>
          {
            billData?.inoculationCharge > 0 && (
                <div className="flex justify-between items-center py-[0.6rem] ">
                  <Typography className="text-[0.9rem] font-sans ">
                    Inoculation Charge
                  </Typography>
                  <Typography className="text-[0.9rem] font-sans">
                    ₹{billData?.inoculationCharge}
                  </Typography>
                </div>
            )
          }

          <div className="flex justify-between items-center py-[0.6rem] mb-[0.5rem] sm:mb-[1rem]">
            <Typography className="text-[1rem] font-sans">
              Discount on MRP
            </Typography>
            <Typography className="text-[1rem] font-sans text-[#389E0D]">
              -₹{billData?.discountOnMRP}
            </Typography>
          </div>
          {billData?.serviceCharge && (
            <div className="flex justify-between items-center py-[0.6rem] mb-[0.5rem] sm:mb-[1rem]">
              <Typography className="text-[1rem] font-sans">
                Convenience fee
              </Typography>
              <Typography className="text-[1rem] font-sans ">
                ₹{billData?.serviceCharge}
              </Typography>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center pt-[0.8rem] sm:py-[0.6rem]">
          <Typography className="text-[1rem] font-semibold font-sans  ">
            Total Amount
          </Typography>
          <Typography className="text-[1rem] font-semibold font-sans">
            ₹{billData?.totalAmount}
          </Typography>
        </div>
        {window.innerWidth > 640 && isButtonVisible ? (
          <>
            <div className="flex justify-end">
              {continueDisable ? (
                <>
                  <button
                    className="my-[0.5rem] bg-[#F15E7E] text-[white] w-[8rem] h-[2.6rem] rounded-[4px]  flex items-center justify-center"
                    type="submit"
                    onClick={handleCheckout}
                  >
                    Continue
                  </button>
                </>
              ) : (
                <>
                  <Tooltip title="Add patient to continue" color="#f386a8">
                    <>
                      <button
                        className="my-[0.5rem] bg-[#BFBFBF] text-[white] w-[8rem] h-[2.6rem] rounded-[4px]  flex items-center justify-center"
                        type="submit"
                      >
                        Continue
                      </button>
                    </>
                  </Tooltip>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="fixed"></div>
          </>
        )}
      </ContainerWithShadow>

      <ViewAllOffersModal
        isOffersOpen={isOfferOpen}
        manageModal={manageOfferModal}
        getData={getData}
      />

      <IscontinueDifferentLabModal
        isOpen={isDifferentLabModalOpen}
        onCancel={() => {
          setIsDifferentLabModalOpen(false);
        }}
      />
    </div>
  );
};

export default BillDetail;
